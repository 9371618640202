import { MayBeNull } from '@wpp-open/core'
import qs from 'qs'

import { facadeApi } from 'api'
import { News } from 'types/news/news'

export interface Params {
  tenantId: string
  hidden: boolean
  primary: MayBeNull<boolean>
  search: string
  countryAlpha2Codes: string[]
  tenantCredentialsIds: string[]
}

export const fetchNewsApi = ({ tenantId, hidden, countryAlpha2Codes, tenantCredentialsIds, primary, search }: Params) =>
  facadeApi.get<News[]>('/news', {
    params: {
      tenant_id: tenantId,
      filter: {
        hidden,
        ...(!!search && { search }),
        ...(!!countryAlpha2Codes?.length && { countryAlpha2Codes }),
        ...(!!tenantCredentialsIds?.length && { tenantCredentialsIds }),
        ...(!!countryAlpha2Codes?.length && { includeNoCountries: false }), //Need to be fixed, Max is aware
        ...(primary !== null && { primary }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
