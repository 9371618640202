import * as zod from 'zod'

import { Credentials, CredentialsAddUpdateDTO, CredentialsForm } from 'types/credentianls/credentials'

export const DESCRIPTION_MAX_CHARACTERS = 200

export const defaultValues: CredentialsForm = {
  title: '',
  url: '',
  clientSecret: '',
  clientId: '',
  realm: '',
  resourceInformationValue: '',
  openInsideOs: true,
  name: '',
  description: '',
  isPrimary: false,
  countryAlpha2Codes: [],
}

export const mapCredentialsToForm = (data: Credentials): CredentialsForm => ({
  title: data.site_domain,
  url: data.site_url,
  clientSecret: data.client_secret,
  clientId: data.client_id,
  realm: data.realm,
  resourceInformationValue: data.resource_information_value,
  openInsideOs: data.open_inside_os,
  name: data.name,
  description: data.description ? data.description : '',
  isPrimary: data.is_primary,
  countryAlpha2Codes: data.country_alpha2_codes || [],
})

export const mapFormToCredentials = (data: CredentialsForm): CredentialsAddUpdateDTO => ({
  client_id: data.clientId,
  client_secret: data.clientSecret,
  realm: data.realm,
  resource_information_value: data.resourceInformationValue,
  site_domain: data.title,
  site_url: data.url,
  open_inside_os: data.openInsideOs,
  name: data.name,
  description: data.description ? data.description : null,
  is_primary: data.isPrimary,
  country_alpha2_codes: data.countryAlpha2Codes.length ? data.countryAlpha2Codes : null,
})

// allows zero for uuid version, in zod this is not possible
const customUuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i

export const validationSchema = zod.object({
  title: zod.string().trim().min(1, 'Please provide Sharepoint site'),
  url: zod.string().url('Not valid url'),
  clientId: zod.string().uuid('Not valid uuid'),
  clientSecret: zod.string().trim().min(1, 'Please provide Client secret'),
  realm: zod.string().uuid('Not valid uuid'),
  resourceInformationValue: zod.string().regex(customUuidRegex, 'Not valid uuid'),
  openInsideOs: zod.boolean(),
  name: zod.string().trim().min(1, 'Name is required').max(128, 'Name must contain at most 128 characters'),
  description: zod.string().max(200, 'Description must contain at most 200 characters'),
  countryAlpha2Codes: zod.string().array(),
  isPrimary: zod.boolean(),
})
