import {
  WppCard,
  WppTypography,
  WppMenuContext,
  WppActionButton,
  WppIconMore,
  WppIconEyeOff,
  WppIconEye,
  WppListItem,
  WppSpinner,
  WppIconEdit,
  WppIconFavourites,
  WppIconFavouritesFilled,
  WppTooltip,
} from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import { ImagePlaceholder } from 'components/imagePlaceholder/imagePlaceholder'
import styles from 'components/newsCard/NewsCard.module.scss'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermissions'
import { useNewsActivationDeactivation } from 'hooks/useNewsActivationDeactivation'
import { useNewsPrimaryNonPrimary } from 'hooks/useNewsPrimaryNonPrimary'
import { News } from 'types/news/news'
import { formatDate } from 'utils/dates'

interface Props {
  newsItem: News
  onEdit: () => void
  locale: string
}

export const NewsCard = ({ newsItem, locale, onEdit }: Props) => {
  const { banner_image, authors, title, created_at: date, guid, hidden, primary } = newsItem
  const { hasPermission } = useHasPermission()
  const hasAccessToNewsSettings = hasPermission(Permission.NewsSettingsManage)
  const { handleActivationDeactivation, isLoading: isLoadingActivationDeactivation } = useNewsActivationDeactivation()
  const { handlePrimaryNonPrimary, isLoading: isLoadingPrimaryNonPrimary } = useNewsPrimaryNonPrimary()

  const isLoading = isLoadingActivationDeactivation || isLoadingPrimaryNonPrimary
  return (
    <WppCard interactive className={styles.card}>
      {isLoading ? (
        <Flex align="center" justify="center" style={{ height: '100%' }}>
          <WppSpinner size="m" />
        </Flex>
      ) : (
        <>
          {hasAccessToNewsSettings && (
            <Flex gap={4} slot="actions">
              <WppTooltip text={`Make ${primary ? 'non-starred' : 'starred'}`}>
                <WppActionButton
                  variant="secondary"
                  style={{ zIndex: 5 }}
                  className={styles.whiteActionButton}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    handlePrimaryNonPrimary(guid, primary)
                  }}
                >
                  {primary ? <WppIconFavouritesFilled /> : <WppIconFavourites />}
                </WppActionButton>
              </WppTooltip>
              <WppMenuContext
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                dropdownConfig={{
                  appendTo: () => document.body,
                  placement: 'bottom-end',
                }}
              >
                <WppActionButton
                  variant="secondary"
                  style={{ zIndex: 5 }}
                  slot="trigger-element"
                  className={styles.whiteActionButton}
                >
                  <WppIconMore direction="horizontal" />
                </WppActionButton>
                <div>
                  <WppListItem onWppChangeListItem={() => onEdit()}>
                    <WppIconEdit slot="left" />
                    <span slot="label">Edit</span>
                  </WppListItem>
                  <WppListItem
                    onWppChangeListItem={() => {
                      handleActivationDeactivation(guid, hidden)
                    }}
                  >
                    {hidden ? <WppIconEye slot="left" /> : <WppIconEyeOff slot="left" />}
                    <span slot="label">{hidden ? 'Show' : 'Hide'}</span>
                  </WppListItem>
                </div>
              </WppMenuContext>
            </Flex>
          )}

          {banner_image ? <img className={styles.cover} src={banner_image} alt="" /> : <ImagePlaceholder />}
          <div className={styles.content}>
            <div className={styles.author}>
              <WppTypography type="s-midi">{authors.join(', ')}</WppTypography>
            </div>
            <div>
              <WppTypography type="m-strong" data-testid="news-title">
                {title}
              </WppTypography>
            </div>
            <div className={styles.footer}>
              <WppTypography className={styles.date} type="xs-body" data-testid="date-label">
                {formatDate(date, locale)}
              </WppTypography>
            </div>
          </div>
        </>
      )}
    </WppCard>
  )
}
